#wire{
	width: 1120px;
	background: #FFF;
	font-size: 1.2rem;
	margin: 40px auto;
	box-shadow: 0 0 32px rgba(#000,.08);
	header{
		padding: 20px 40px;
		border-bottom: $border 1px solid;
		@include flex();
		position: relative;
		ul{
			@include flex();
			li{
				margin: 0 0 0 40px;
			}
		}
	}
	section{
		padding: 72px 40px;
		border-bottom: $border 1px solid;
		position: relative;
	}
	.tip{
		font-size: 1rem;
		color: #FFF;
		background: #444;
		padding: 8px 32px;
		position: absolute;
		top: 40px;
		right: -20px;
		z-index: 2;
		box-shadow: 8px 8px 24px rgba(#000,.16);
		&:after{
			content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 8px 20px 8px 0;
            border-color: transparent #444 transparent transparent;
			position: absolute;
			top: 50%;
			left: -20px;
			transform: translateY(-50%);
		}
	}
	.flex{
		@include flex();
		justify-content: center;
		align-items: center;
		&.just{
			justify-content: space-between;
		}
		&.start{
			justify-content: flex-start;
		}
		&.end{
			justify-content: flex-end;
		}
	}
	.gray{
		background: #f2f2f2;
	}
	.white{
		background: #FFF;
	}
	.dark{
		background: #444;
		color: #FFF;
		span.a{
			border-color: #FFF;
			&:before,
			&:after{
				background: #FFF;
			}
		}
		.btn{
			background: #666;
		}
	}
	.mb{
		margin-bottom: 24px;
	}
	.mb-s{
		margin-bottom: 12px;
	}
	.shadow{
		box-shadow: 4px 4px 8px rgba(#000,.04), 0 0 8px rgba(#000,.02);
	}
	.left{
		text-align: left;
	}
	.right{
		text-align: right;
	}
	.center{
		text-align: center;
	}
	.bold{
		font-weight: 700;
	}
	.large{
		font-size: 2rem;
	}
	.bo{
		border: $border 2px solid;
	}
	.bo-t{
		border-top: $border 2px solid;
	}
	.bo-l{
		border-left: $border 2px solid;
	}
	.bo-r{
		border-right: $border 2px solid;
	}
	.bo-b{
		border-bottom: $border 2px solid;
	}
	.pa{
		padding: 1.25rem;
	}
	.btn{
		min-width: 300px;
		width: 33%;
		margin: 32px auto 0;
		padding: 1rem 0;
		text-align: center;
		font-weight: 700;
		background: #888;
		color: #FFF;
	}
	span.a{
		display: inline-block;
		width: 1.2rem;
		height: 1.2rem;
		margin-right: .24rem;
		border: $normal 1px solid;
		overflow: hidden;
		position: relative;
		vertical-align: bottom;
		&:before,
		&:after{
			content: '';
			width: 1px;
			height: 150%;
			position: absolute;
			top: 50%;
			left: 50%;
			background: $normal;
			opacity: .88;
			transform: translate(-50%, -50%) rotate(45deg);
		}
		&:after{
			transform: translate(-50%, -50%) rotate(-45deg);
		}
	}
	@for $i from 1 through 100{
		.w-#{$i}{
			width: 1% * $i;
		}
		.h-#{$i}{
			height: 10px * $i;
		}
	}
}
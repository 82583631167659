@charset "utf-8";

@import "module/reset";
@import "module/module";

//USE ASSETS
@import "plugin/swiper";

/* BASE COLOR
------------------------------------------------------------*/
$normal:#000;
$link:#D02;
$border:#DDD;
$ease: cubic-bezier(0.65, 0, 0.35, 1);

/* SITE SETTING
------------------------------------------------------------*/
$width: 1120px;
$tab: $width - 1px;
$phone: 640px;

$pc-head:80px;
$sp-head:60px;

:root{
	font-size: 14px;
}

@media screen and (min-width: $width){
	@include default(
		$width, //サイト幅
		1rem, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		#FFF //ボディ背景
	);
}

@media screen and (max-width: $tab){
	@include default(
		auto, //サイト幅
		1rem, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		#FFF //ボディ背景
	);
}

@media screen and (max-width: $phone){
	@include default(
		auto, //サイト幅
		1rem, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		#FFF //ボディ背景
	);
}

@import "module/wire";
@import "common/header";
@import "common/footer";
@import "page/index";

#site-wrapper,#site-footer{
    position: relative;
    top: $pc-head;
    @media screen and (max-width:768px){
        top: $sp-head;
    }
}